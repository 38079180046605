import axios from "axios";


export default {
    
    getCategories:async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_API+'/get_categories');
            return response.data;
        } catch (error) {
            throw error;
        }
    },


}
