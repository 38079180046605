import Axios from "axios";

export const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Authorization": "none"
};

function returnAxiosInstance(customHeaders = headers) {
    return Axios.create({
        baseURL: process.env.REACT_APP_URL_API,
        timeout: 5000,
        headers: customHeaders,
    });
}

export function get(url, customHeaders = headers, params = {}) {
    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("######################TOKEN CANCELLED#################################")
    }
    cancelToken = Axios.CancelToken.source();
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url, { cancelToken: cancelToken.token, params });
}

export function post(url, requestData, customHeaders = headers) {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, requestData);
}
