import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import StockService from '../../../api/StockAPI'
import * as commonActions from '../../../state/common/commonActions'
import { compactText, n_format, t } from '../../../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import Motion1 from '../../../common/components/Motion1'
import moment from 'moment'

const ProductRow = ({ setFocusedInput, item, index = 1, header, isHistory = false, isReturn = false, showModal, setShowModal, setSelectedItem, getProducts, entryType = 'in' }) => {
	const [addStockText, setAddStockText] = useState(t('Add Stock'))
	const [editText, setEditText] = useState(t('Edit'))
	const [oldStock, setOldStock] = useState(item?.stock)
	const user = useSelector((state) => state.user)
	const [done, setDone] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().substr(0, 10))
	const [dateEdit, setDateEdit] = useState(item?.expiry_date)
	const [disable, setDisable] = useState(false)
	const [isReturnState, setIsReturnState] = useState('N')
	const [isReturnEditState, setIsReturnEditState] = useState(item?.is_return)

	const [stock, setStock] = useState(item?.stock && item?.entry_type || '')
	const common = useSelector((state) => state.common)
	const dispatch = useDispatch()

	const setSearchTextFromVK = () => common.activeField == 'SM_stock' + item?.id && setStock(common.text)

	useEffect(() => {
		setSearchTextFromVK()
	}, [common.activeField, common.text])

	function onlyNumbers(str) {
		return /^[0-9]*$/.test(str)
	}

	const onStockChange = (e) => {
		let value = e.target.value
		if (value !== '' && !onlyNumbers(value)) {
			alert('Only 0-9 are allowed')
			return
		}
		common.activeField == 'SM_stock' && dispatch(commonActions.setText(value))
		setStock(value)
	}

	const addStock = async () => {
		if (!stock) {
			alert(t('Please enter stock'))
			return
		}

		dispatch(commonActions.setLoading(true))
		setAddStockText(t('Adding...'))
		setDisable(true)
		try {
			const response = await StockService.addStock(
				{
					entry_type: entryType,
					product_id: item?.id,
					stock: stock,
					expiry_date: date,
					is_return: isReturnState,
					user_id: user?.data?.id,
					branch_id: user?.data?.branch_id,
				},
				user?.data?.api_token
			)
			dispatch(commonActions.setLoading(false))
			if (response?.success) {
				setAddStockText(t('Updated'))
				setOldStock(parseInt(oldStock) + parseInt(stock))
				setDone(true)
				setDisable(false)
				getProducts(item?.barcode)
				setStock('');
				setIsReturnState('N')
				setDate(new Date().toISOString().substr(0, 10));
			} else {
				console.log(response)
				setAddStockText(t('Add Stock'))
				setDisable(false)
			}
		} catch (error) {
			dispatch(commonActions.setLoading(false))
			console.log(error)
		}
	}

	const onEdit = async () => {
		if (!isEdit) {
			// setStockEdit(item?.stock)
			setIsEdit(!isEdit)
			setEditText('Save')
			dispatch(commonActions?.setActiveField('SM_stock' + item?.id))
			dispatch(commonActions.setText(item?.stock))
			return
		}

		if (parseInt(stock) >= 0 || dateEdit != item?.expiry_date) {
			dispatch(commonActions.setLoading(true))
			try {
				const response = await StockService.updateStock(
					{
						id: item?.stock_id,
						stock: stock,
						entry_type: entryType,
						expiry_date: dateEdit,
						is_return: isReturnEditState,
					},
					user?.data?.api_token
				)
				console.log(response)
				dispatch(commonActions.setLoading(false))
				if (response?.success) {
					setIsEdit(!isEdit)
					setEditText('Edit')
					getProducts(item?.barcode)
				} else {
					console.log(response)
				}
			} catch (error) {
				dispatch(commonActions.setLoading(false))
				console.log(error)
			}
		} else {
			dispatch(commonActions.setLoading(false))
			alert('Enter Stock Please')
			return
		}
	}

	const onDateChange = (e) => setDate(e.target.value)

	useEffect(() => {
		setDone(false)
		setAddStockText(t('Add Stock'))
		setOldStock(item?.stock)
	}, [item])

	console.log({ isReturnEditState })
	return (
		<>
			<Motion1>
				<ProductItem done={done} isHeader={header} isHistory={isHistory} isReturn={isReturn}>
					<div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
						<h5 style={{ width: '5%', textAlign: 'center' }}>{item?.item_code}</h5>
						{/* <h5 style={{ width: '10%', textAlign: 'center' }}>{item?.date == 'Last Updated Date' ? item?.date : moment(item?.updated_at).format('D/M/Y')} </h5> */}
						<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.barcode} </h5>
						<h5 style={{ width: '12%', textAlign: 'center' }}>{compactText(item?.name_en, 50)} </h5>
						<h5 style={{ width: '10%', textAlign: 'center' }}>
							{header ? '' : '$'}
							{item?.price == 'Price' ? item?.price : n_format(item?.price)}
						</h5>
						<h5 style={{ width: '8%', textAlign: 'right' }}>
							{isHistory ? (
								isEdit ? (
									<>
										<label>
											<input value="Y" type="radio" onChange={() => setIsReturnEditState('Y')} checked={isReturnEditState == 'Y'} /> Yes
										</label>
										&ensp;
										<label>
											<input value="N" type="radio" onChange={() => setIsReturnEditState('N')} checked={isReturnEditState == 'N'} /> No
										</label>
									</>
								) : item?.is_return == 'Y' ? (
									'Yes'
								) : (
									'No'
								)
							) : header ? (
								item?.is_return
							) : (
								<>
									<label>
										<input value="Y" type="radio" name="is_return" onClick={() => setIsReturnState('Y')} checked={isReturnState == 'Y'} /> Yes
									</label>
									&ensp;
									<label>
										<input value="N" type="radio" name="is_return" onClick={() => setIsReturnState('N')} checked={isReturnState == 'N'} /> No
									</label>
								</>
							)}
						</h5>
						<h5 style={{ width: '8%', textAlign: 'right' }}>
							{(isHistory && !isEdit) || header ? (
								oldStock
							) : (
								<>
									<input
										value={stock}
										onChange={onStockChange}
										onFocus={(e) => {
											dispatch(commonActions.emptyText())
											dispatch(commonActions?.setActiveField('SM_stock' + item?.id))
											dispatch(commonActions.setText(e.target.value))
											dispatch(commonActions?.toggleKeyboard(true))
										}}
										type="text"
										min={1}
										style={{ height: 30, fontSize: 17, width: '100%', paddingLeft: 10 }}
									/>
								</>
							)}
						</h5>
						{/* <h5 style={{ width: "5%", textAlign: "left" }}>{typeof (item?.stock_out) == "string" ? item?.stock_out : item?.stock_out?.reduce((a, b) => a + parseInt(b.stock), 0)} </h5> */}

						{isHistory ? (
							<h5 style={{ width: '12%', textAlign: 'center' }}>
								{isHistory ? (
									isEdit ? (
										<input value={dateEdit} onChange={(e) => setDateEdit(e.target.value)} type="date" style={{ height: 30, fontSize: 17, width: '100%', paddingLeft: 10 }} />
									) : (
										moment(item?.expiry_date).format('D/M/Y')
									)
								) : (
									moment(item?.expiry_date).format('D/M/Y')
								)}
							</h5>
						) : (
							<h5 style={{ width: '10%', textAlign: 'center', marginRight: 10 }}>
								{header ? item?.expiry_date : <input value={date} placeholder="dd-mm-yyyy" onChange={onDateChange} type="date" style={{ height: 30, fontSize: 12, width: '100%', paddingLeft: 10 }} />}
							</h5>
						)}
						{isHistory ? (
							<>
								<h4 style={{ width: '10%', textAlign: 'center' }}>
									<Button color="#025169" onClick={onEdit}>
										<h4 style={{ color: '#fff' }}>{editText}</h4>
									</Button>
								</h4>
							</>
						) : header ? (
							<h4 style={{ width: '10%', textAlign: 'center' }}>Action</h4>
						) : (
							<>
								{disable == false ? (
									<Button color="#000" onClick={addStock}>
										<h4 style={{ color: '#fff' }}>{addStockText}</h4>
									</Button>
								) : (
									<Button color="#000">
										<h4 style={{ color: '#fff' }}>Wait Moment..</h4>
									</Button>
								)}
							</>
						)}
					</div>
				</ProductItem>
			</Motion1>
		</>
	)
}

export default ProductRow

const ProductItem = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: ${({ isHistory, done, isHeader, stockAdd, isReturn }) => (isHistory ? (isReturn ? '#ffcccb' : '#87f8c9') : done ? '#ecfc91' : isHeader ? '#fcfcfc' : '#fcf5fa')};
	margin: 10px 20px;
	justify-content: center;
	/* cursor: pointer; */
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	margin-bottom: ${({ isHistory, isHeader }) => (!isHistory && !isHeader ? '80px' : ' unset')};
	padding-top: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	padding-bottom: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const Button = styled.div`
	cursor: pointer;
	background-color: ${({ color }) => color};
	padding: 10px 15px;
	border-radius: 4px;
	margin-right: 10px;
	border: 2px solid ${({ color }) => color};

	&:hover {
		border: 2px solid white;
	}
`
