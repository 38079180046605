import axios from "axios";
import { data } from "./../utils/offline/users.json";
import bcrypt from "bcryptjs"
const apis = {
  signIn: async (name,password) => {
    try {
      if(navigator.onLine){
      const response = await axios.get(
        process.env.REACT_APP_URL_API + "/signIn?name=" +name+"&password="+password
      );
      console.log(response.data,"lkok");
      return response.data;
      }
      else{
        const users = [...data];
        //  const response = users.filter(e => e.name === name && bcrypt.compareSync(password, e.password));
         const response = users.filter(e => e.name.toString().toLowerCase() === name.toString().toLowerCase() && bcrypt.compareSync(password, e.password));
         console.log(response);

         if(response.length > 0){
           return {data:response[0],success: "true"};
         }
         else{
          return {errors: "Username or Password Invalid", success: false};
         }
      }


    } catch (error) {
      if (!error.response) {
        const users = [...data];
        //  const response = users.filter(e => e.name === name && bcrypt.compareSync(password, e.password));
         const response = users.filter(e => e.name.toString().toLowerCase() === name.toString().toLowerCase() && bcrypt.compareSync(password, e.password));
         console.log(response);

         if(response.length > 0){
           return {data:response[0],success: "true"};
         }
         else{
          return {errors: "Username or Password Invalid", success: false};
         }
      }
      else{
        throw error;
      }
    }
  },

  addCustomer: async (customer) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_API +
          `/add_customer?phone=${customer?.phone}&name=${customer?.name}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apis;
