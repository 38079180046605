import React from 'react'
import ModalHeader from './ModalHeader'

const MyModal = ({ children, header, show, toggleModal, onOk, full, title, bottomOptions, handleBack, style = {}, title_1, title_2, title_3, isSaving = false }) => {
	return (
		<div className={show ? (full ? `modal-full-lg` : `modal-full`) : `modal-hide`} style={style}>
			{!full ? (
				<div className="modal-body">
					{header ? <h4 className="text-1 t-center">{header}</h4> : null}
					<div className="w-100">
						{!bottomOptions ? (
							<ModalHeader title_1={title_1} title_2={title_2} isSaving={isSaving} title_3={title_3} handle1={onOk} handle3={handleBack} headTitle={title} handle2={toggleModal} />
						) : null}
						<br />
						<div className="row justify-center w-100">{children()}</div>
						{bottomOptions ? (
							<ModalHeader title_1={title_1} title_2={title_2} title_3={title_3} isSaving={isSaving} handle1={onOk} handle3={handleBack} headTitle={title} handle2={toggleModal} />
						) : null}
					</div>
				</div>
			) : (
				children()
			)}
		</div>
	)
}

export default MyModal
