import MyModal from '../../../common/components/MyModal'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductRow } from '.'
import StockService from '../../../api/StockAPI'
import ProductService from '../../../api/ProductAPI'
import { t } from '../../../utils/common'
import * as stockActions from '../../../state/stock/stockActions'
import * as commonActions from '../../../state/common/commonActions'
import styled from 'styled-components'

const ProductTable = ({ entryType = 'in' }) => {
	// Props

	// States
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)
	const [returnStock, setReturnStock] = useState(1)
	const [returnReason, setReturnReason] = useState('')

	// Redux
	const stock = useSelector((state) => state.stock)
	const user = useSelector((state) => state.user)
	const dispatch = useDispatch()

	// Functions
	// confirm Modal

	const onOk = () => {
		onReturn()
	}

	const onReturn = async () => {
		dispatch(commonActions.setLoading(true))
		try {
			const response = await StockService.returnStock(
				{
					stock: returnStock,
					log_id: selectedItem?.stock_id,
					reason: returnReason,
				},
				user?.data?.api_token
			)
			dispatch(commonActions.setLoading(false))
			if (response?.success) {
				setShowModal(!showModal)
				setReturnStock(1)
				setReturnReason('')
				getProducts(selectedItem?.barcode)
			} else {
				console.log(response)
			}
		} catch (error) {
			dispatch(commonActions.setLoading(false))
			console.log(error)
		}
	}

	// const onDelete = async () => {
	// 	try {
	// 		const response = await StockService.updateStock(
	// 			{
	// 				stock_id: selectedItem?.stock_id,
	// 				operation: '-1',
	// 				stock_update: selectedItem?.stock,
	// 				stock: selectedItem?.stock,
	// 			},
	// 			user?.data?.api_token
	// 		)

	// 		if (response?.success) {
	// 			setShowModal(!showModal)
	// 			getProducts(selectedItem?.barcode)
	// 		} else {
	// 			console.log(response)
	// 		}
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }

	const getProducts = async (barcode) => {
		dispatch(commonActions.setLoading(true))
		console.log(barcode, 'Inside Get Products Code Stock')
		try {
			const product = await ProductService.searchProducts({ barcode, entry_type: entryType, with: 'logs', limit: 1 })
			dispatch(commonActions.setLoading(false))
			console.log(product, 'Response from Get products')
			if (product.data?.length > 0) {
				dispatch(stockActions.addProduct(product.data[0]))
			}
		} catch (error) {
			dispatch(commonActions.setLoading(false))
			console.warn(error)
		}
	}
	console.log(entryType)

	// Side Effects

	// JSX
	return (
		<div>
			{stock.products?.length > 0 ? (
				<>
					<ProductDiv>
						Product Stock Out By Customer Orders: {JSON.stringify(stock.products[0]['stock_sold'])} | Total Stock: {stock.products[0].stock}
					</ProductDiv>
					<ProductRow getProducts={getProducts} key={0} item={stock.products[0]} isEdit={true} entryType={entryType} />
					<ProductRow
						header
						item={{
							id: 0,
							item_code: t('Item Code'),
							date: t('Last Updated'),
							barcode: t('Barcode'),
							name_en: t('Name'),
							price: t('Price'),
							stock: t(['out', 'manual'].includes(entryType) ? 'Stock out' : 'Stock in'),
							is_return: t('Return'),
							expiry_date: t('Expiry Date'),
						}}
					/>

					{stock.products[0]?.logs?.length > 0
						? stock.products[0]?.logs
								?.map((stock_item, index) => {
									return (
										<ProductRow
											getProducts={getProducts}
											showModal={showModal}
											isReturn={stock_item.is_return == 'Y'}
											setShowModal={setShowModal}
											setSelectedItem={setSelectedItem}
											isHistory={true}
											key={stock_item.id.toString()}
											entryType={entryType}
											item={{
												...stock.products[0],
												// logs: stock_item?.logs,
												id: stock_item?.id,
												stock_product: stock.products[0]?.stock,
												stock: stock_item?.stock,
												stock_id: stock_item?.id,
												is_return: stock_item?.is_return == 'Y' ? 'Y' : 'N',
												expiry_date: stock_item?.expiry_date,
												updated_at: stock_item?.updated_at,
											}}
										/>
									)
								})
								.reverse()
						: null}
				</>
			) : (
				<h2 style={{ textAlign: 'center', margin: 20 }}>No Product Found</h2>
			)}
			{/* 
			<MyModal
				children={() => {
					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<input
								type="number"
								placeholder={`Max ${selectedItem?.stock < selectedItem?.stock_product ? selectedItem?.stock : selectedItem?.stock_product}`}
								style={{ height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 }}
								max={selectedItem?.stock < selectedItem?.stock_product ? selectedItem?.stock : selectedItem?.stock_product}
								value={returnStock}
								min={1}
								onChange={(e) => {
									if (e.target.value > selectedItem?.stock || e.target.value > selectedItem?.stock_product) {
										alert('Max: ' + (selectedItem?.stock < selectedItem?.stock_product ? selectedItem?.stock : selectedItem?.stock_product))
										return
									}
									setReturnStock(e.target.value)
								}}
							/>
							<input type="date" style={{ height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 }} disabled value={selectedItem?.expiry_date} />
							<select style={{ height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 }} onChange={(e) => setReturnReason(e.target.value)}>
								{[
									{
										name: 'Selected',
										value: '',
									},
									{
										name: 'Expired',
										value: 'expired',
									},
									{
										name: 'Damaged',
										value: 'damaged',
									},
									{
										name: 'Broken',
										value: 'broken',
									},
									{
										name: 'Extra',
										value: 'extra',
									},
									{
										name: 'Other',
										value: 'other',
									},
								].map((e) => {
									return (
										<option value={e.value} key={e.value} selected={e.value == returnReason}>
											{e.name}
										</option>
									)
								})}
							</select>
						</div>
					)
				}}
				show={showModal}
				onOk={onOk}
				title_1="Return"
				title_2="Cancel"
				title={t('Confirm Return to factory  ?')}
				style={{ position: 'fixed', zIndex: 22 }}
				toggleModal={() => {
					setReturnStock(1)
					setReturnReason('')
					setShowModal(!showModal)
				}}
			/> */}
		</div>
	)
}

const ProductDiv = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: #ecfc91;
	margin: 0px 20px;
	justify-content: center;
	/* cursor: pointer; */
	margin-top: 10px;
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

export default ProductTable
