import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductService from "../../../api/ProductAPI";
import CustomerService from "../../../api/CustomerAPI";
import SyncInvoiceService from "../../../utils/offline/invoiceApis";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as commonActions from "../../../state/common/commonActions";
import * as offlineActions from "../../../state/offline/offlineActions";
import BarcodeReader from "react-barcode-reader";

import Clock from "react-live-clock";
import AddCustomer from "./AddCustomer";
import ChooseCustomers from "./ChooseCustomers";

import {
  FaSearch,
  FaToggleOff,
  FaToggleOn,
  FaWindowClose,
  FaSync,
} from "react-icons/fa";
import { useHistory } from "react-router";
import CommonModal from "../../../common/components/CommonModal";
import { downloadFile } from "../../../utils/common";
import InternetStatus from "./InternetStatus";

function Navbar() {
  const [searchText, setSearchText] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [mode, setMode] = useState(true);
  const [usingScanner, setUsingScanner] = useState(false);
  const [synced, setSynced] = useState(false);
  const [syncing, setSyncing] = useState(false);

  // Choose Customer
  const [allCustomers, setAllCustomers] = useState([]);
  const [showChooseCustomers, setShowChooseCustomers] = useState(false);
  const toggleChooseCustomers = () => {
    setShowChooseCustomers(!showChooseCustomers);
    if (!showChooseCustomers) {
      dispatch(orderActions.setCustomer(null));
      dispatch(customerActions.addCustomer({}));
    } else {
      showKeyboard();
    }
  };

  const history = useHistory();

  const order = useSelector((state) => state.order);
  const customer = useSelector((state) => state.customer);
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const offline = useSelector((state) => state.offline);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showCustomerNotFound, setShowCustomerNotFound] = useState(false);
  const [customerField, setCustomerField] = useState(false);

  const toggleNotFound = () => {
    setShowNotFound(!showNotFound);
  };
  const onOkNotFound = () => {
    setShowNotFound(!showNotFound);
  };

  const toggleCustomerNotFound = () => {
    setShowCustomerNotFound(!showCustomerNotFound);
  };

  const onOkCustomerNotFound = () => {
    setShowCustomerNotFound(!showCustomerNotFound);
  };

  const dispatch = useDispatch();

  const handleScan = async (code) => {
    setSearchText("");
    dispatch(commonActions?.emptyText());
    setUsingScanner(true);
    setSearchText(code);
    if (code) {
      getProducts(code);
    }
  };
  const handleError = (err) => {
    console.error(err);
    setSearchText("");
    dispatch(commonActions?.emptyText());
    toggleNotFound();
  };

  const searchData = async (e) => {
    setSearchText(e.target.value);
    if (e.target.value) {
      getProducts(e.target.value);
    }
  };

  const searchDataOnType = async (e) => {
    if (e) {
      getProducts(e);
    }
  };

  // get products array from api
  const getProducts = async (value) => {
    if (!order?.printed) {
      try {
        const data = await ProductService.searchProducts({ barcode: value });
        if (data.data.length > 0) {
          setSearchText("");
          let myProduct = data.data[0];

          if (myProduct?.pack_type === "O") {
            const rn = Math.floor(Math.random() * (999 - 100 + 1) + 100);
            const price = (
              parseFloat(value?.toString().substr(6, 6)) / 100
            ).toFixed(2);
            dispatch(
              orderActions.setOrderProducts({
                ...myProduct,
                price,
                barcode: value,
              })
            );
          } else {
            dispatch(orderActions.setOrderProducts(myProduct));
          }
          dispatch(commonActions?.emptyText());
        } else {
          if (usingScanner) {
            setSearchText("");
            dispatch(commonActions?.emptyText());
            toggleNotFound();
          } else {
            toggleNotFound();
          }
        }
      } catch (error) {
        if (usingScanner) {
          setSearchText("");
          dispatch(commonActions?.emptyText());
        }
      }
    }
  };

  const onCustomerPhoneChange = async (e) => {
    dispatch(commonActions.setText(e.target.value));
    setCustomerPhone(e.target.value);
    getCustomers(e.target.value);
  };

  // const onCustomerPhoneChangeOnType = async (e) => {
  //   if (e) {
  //     const data = await CustomerService.getCustomer(e);
  //     console.log(data);
  //     if (data.length > 0) {
  //       setCustomerPhone("");
  //       dispatch(commonActions?.emptyText());
  //       dispatch(orderActions.setCustomer(data[0]["id"]));
  //       dispatch(customerActions.addCustomer(data[0]));
  //     } else {
  //       dispatch(orderActions.setCustomer(null));
  //       dispatch(customerActions.addCustomer({}));
  //     }
  //   }
  // };

  // Get All customers from API
  const getCustomers = async (value) => {
    if (customerField) {
      if (value && value.toString().length >= 8) {
        const response = await CustomerService.getCustomerById(value);
        const data = response;
        if (data.length > 0) {
          setCustomerPhone("");
          dispatch(commonActions?.emptyText());
          setAllCustomers(data);
          toggleChooseCustomers();
        } else {
          toggleCustomerNotFound();
          dispatch(orderActions.setCustomer(null));
          dispatch(customerActions.addCustomer({}));
        }
      }
    } else {
      if (value && value.toString().length >= 4) {
        const data = await CustomerService.getCustomer(value);
        if (data.length > 0) {
          setCustomerPhone("");
          dispatch(commonActions?.emptyText());
          setAllCustomers(data);
          toggleChooseCustomers();
        } else {
          toggleCustomerNotFound();
          dispatch(orderActions.setCustomer(null));
          dispatch(customerActions.addCustomer({}));
        }
      }
    }
  };

  const setCustomer = (i) => {
    dispatch(orderActions.setCustomer(allCustomers[i]["id"]));
    dispatch(customerActions.addCustomer(allCustomers[i]));
    dispatch(commonActions?.setActiveField("B"));
  };

  const toggleAddNewCustomer = () => {
    setShowAddCustomer(!showAddCustomer);
    dispatch(commonActions?.setActiveField("B"));
  };

  const showKeyboard = (show = null) => {
    dispatch(commonActions.toggleKeyboard(show));
  };

  // Sync Data
  // const syncData = async (online) => {

  //   console.log(offline,"helloelljnk");
  //     if(online){
  //       let  i= 0;let j=0;
  //       if(offline?.invoices?.length > 0){
  //         i=i+1;
  //         console.log(i,j,"First Record");
  //         offline?.invoices.forEach(async (invoice) =>  {
  //           j=j+1;
  //           console.log(i,j,"Second Record");
  //           const response =  await SyncInvoiceService.addOrder(invoice);
  //           if (response.success) {
  //             await dispatch(offlineActions.deleteOfflineInvoice(invoice.id))
  //           console.log("Success",invoice);
  //         }
  //       });
  //       // dispatch(offlineActions.emptyOfflineInvoices())
  //     }
  //       if(offline?.customers?.length > 0){

  //       }
  //     }
  // }

  useEffect(() => {
    if (common?.activeField) {
      if (common?.activeField === "B") {
        setSearchText(common?.text);
        if (common.enter) {
          searchDataOnType(common?.text);
          dispatch(commonActions.onEnter(false));
        }
      } else if (common?.activeField === "P") {
        setCustomerPhone(common?.text);
        if (common?.text.toString().length >= 4) {
          if (!customerField) {
            getCustomers(common?.text);
          } else {
            if (common.enter) {
              getCustomers(common?.text);
              dispatch(commonActions.onEnter(false));
            }
          }
        }
      }
    } else {
    }
    setUsingScanner(false);
  }, [common.text, common.enter]);

  useEffect(() => {
    dispatch(commonActions.emptyText());
  }, [common?.activeField]);

  useEffect(() => {
    setCustomerPhone(customer.data?.phone || customerPhone || "");
  }, [customer.data]);

  useEffect(() => {
    if (!order?.customer_id) {
      setCustomerPhone("");
      dispatch(commonActions?.emptyText());
    }
  }, [order?.customer_id]);

  const syncData = async () => {
    if (offline?.invoices?.length > 0) {
      downloadFile(offline?.invoices);
      setSyncing(true);
      await offline?.invoices.forEach(async (invoice) => {
        const response = await SyncInvoiceService.addOrder(invoice);
        if (response.success) {
          await dispatch(offlineActions.deleteOfflineInvoice(invoice.id));
          console.log("Success", invoice);
        }
      });
      // setSyncing(false)
      // dispatch(offlineActions.emptyOfflineInvoices())
    }
    if (offline?.customers?.length > 0) {
    }
  };

  return (
    <div className="bg-1 navbar-1">
      <AddCustomer toggle={toggleAddNewCustomer} show={showAddCustomer} />
      <CommonModal
        children={() => {}}
        show={showNotFound}
        cancel={false}
        onOk={onOkNotFound}
        title="Barcode Not Found"
        toggleModal={toggleNotFound}
      />
      <CommonModal
        children={() => {}}
        show={showCustomerNotFound}
        cancel={false}
        onOk={onOkCustomerNotFound}
        title="Customer Not Found"
        toggleModal={toggleCustomerNotFound}
      />
      <ChooseCustomers
        toggle={toggleChooseCustomers}
        show={showChooseCustomers}
        customers={allCustomers}
        setCustomer={setCustomer}
      />
      <div className="search-bar">
        <div className="column justify-center" style={{ position: "relative" }}>
          <BarcodeReader onError={handleError} onScan={handleScan} />
          <input
            type="text"
            value={searchText}
            onChange={searchData}
            // onFocus={() => dispatch(commonActions?.setActiveField("B"))}
            style={{
              color: common?.activeField === "B" ? "#FFF" : "#000",
              fontSize: 18,
              paddingLeft: 10,
              fontWeight: "bold",
              fontFamily: "Arial",
            }}
            className={`${common?.activeField === "B" ? "active-field" : ""}`}
          />
          <div
            onClick={() => dispatch(commonActions?.setActiveField("B"))}
            style={{
              height: 30,
              width: "100%",
              top: 0,
              right: 0,
              fontSize: 18,
              paddingLeft: 10,
              position: "absolute",
            }}
          ></div>
          <p className="text-1">Barcode</p>
        </div>
        <FaSearch
          onClick={() => history.push("/product-search")}
          style={{ color: "#fff", fontSize: "28px" }}
        />
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            showKeyboard(true);
            dispatch(commonActions?.setActiveField("B"));
          }}
        >
          <path d="M5.625 11.25H8.125V13.75H5.625V11.25Z" fill="white" />
          <path d="M12.5 11.25H10V13.75H12.5V11.25Z" fill="white" />
          <path d="M14.375 11.25H16.875V13.75H14.375V11.25Z" fill="white" />
          <path d="M25.625 11.25H23.125V13.75H25.625V11.25Z" fill="white" />
          <path d="M31.875 11.25H34.375V13.75H31.875V11.25Z" fill="white" />
          <path d="M30 11.25H27.5V13.75H30V11.25Z" fill="white" />
          <path d="M18.75 11.25H21.25V13.75H18.75V11.25Z" fill="white" />
          <path d="M8.125 15.5H5.625V18H8.125V15.5Z" fill="white" />
          <path d="M10 15.5H12.5V18H10V15.5Z" fill="white" />
          <path d="M16.875 15.5H14.375V18H16.875V15.5Z" fill="white" />
          <path d="M23.125 15.5H25.625V18H23.125V15.5Z" fill="white" />
          <path d="M34.375 15.5H31.875V18H34.375V15.5Z" fill="white" />
          <path d="M27.5 15.5H30V18H27.5V15.5Z" fill="white" />
          <path d="M21.25 15.5H18.75V18H21.25V15.5Z" fill="white" />
          <path d="M5.625 20H8.125V22.5H5.625V20Z" fill="white" />
          <path d="M30 20H10V22.5H30V20Z" fill="white" />
          <path d="M31.875 20H34.375V22.5H31.875V20Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 6.875C1.80969 6.875 1.25 7.43462 1.25 8.125V25.625C1.25 26.3154 1.80969 26.875 2.5 26.875H37.5C38.1903 26.875 38.75 26.3154 38.75 25.625V8.125C38.75 7.43462 38.1903 6.875 37.5 6.875H2.5ZM3.125 8.75V25H36.875V8.75H3.125Z"
            fill="white"
          />
          <path
            d="M20 35.8077L14.6564 30.5756L13.4998 31.7084L20.0024 38.0756L26.4996 31.7084L25.343 30.5756L20 35.8077Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="customer-search">
        <div className="column justify-around">
          <p
            className="text-1 text-5"
            style={{ fontWeight: order.customer_id ? "bold" : "normal" }}
          >
            Customer
          </p>
          <p
            className="text-1 text-5"
            style={{ fontWeight: !order.customer_id ? "bold" : "normal" }}
          >
            {order.customer_id ? customer?.data?.name : "Walk In"}
          </p>
        </div>

        <div className="column justify-center w-50 ml-1">
          <div className="row">
            <div className="row" style={{ position: "relative", width: "80%" }}>
              <input
                className={`${
                  common?.activeField === "P" ? "active-field" : ""
                }`}
                value={customerPhone}
                onChange={onCustomerPhoneChange}
                style={{
                  color: common?.activeField === "P" ? "#fff" : "#000",
                  fontSize: 18,
                  paddingLeft: 10,

                  fontWeight: "bold",
                  fontFamily: "Arial",
                }}
                type="text"
                // onFocus={() => {
                //   dispatch(commonActions?.setActiveField("P"));
                //   // showKeyboard(true);
                // }}
              />
              <div
                onClick={() => dispatch(commonActions?.setActiveField("P"))}
                style={{
                  height: 30,
                  width: "100%",
                  top: 0,
                  right: 0,
                  fontSize: 18,
                  paddingLeft: 10,
                  position: "absolute",
                }}
              ></div>
            </div>
            <div
              className="input-btn"
              onClick={() => {
                setCustomerPhone("");
                dispatch(commonActions?.emptyText());
                dispatch(orderActions.setCustomer(null));
                dispatch(customerActions.addCustomer({}));
                dispatch(commonActions?.setActiveField("P"));
              }}
            >
              <FaWindowClose color="#fff" size={31} />
            </div>
          </div>
          {customerField ? (
            <p className="text-1">Customer ID Search</p>
          ) : (
            <p className="text-1">Phone Search</p>
          )}
        </div>
        {customerField ? (
          <FaToggleOn
            onClick={() => setCustomerField(false)}
            color="#0f0"
            style={{ height: 30, width: 55 }}
          />
        ) : (
          <FaToggleOff
            onClick={() => setCustomerField(true)}
            color="#fff"
            style={{ height: 30, width: 55 }}
          />
        )}
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            showKeyboard(true);
            dispatch(commonActions?.setActiveField("P"));
          }}
        >
          <path d="M5.625 11.25H8.125V13.75H5.625V11.25Z" fill="white" />
          <path d="M12.5 11.25H10V13.75H12.5V11.25Z" fill="white" />
          <path d="M14.375 11.25H16.875V13.75H14.375V11.25Z" fill="white" />
          <path d="M25.625 11.25H23.125V13.75H25.625V11.25Z" fill="white" />
          <path d="M31.875 11.25H34.375V13.75H31.875V11.25Z" fill="white" />
          <path d="M30 11.25H27.5V13.75H30V11.25Z" fill="white" />
          <path d="M18.75 11.25H21.25V13.75H18.75V11.25Z" fill="white" />
          <path d="M8.125 15.5H5.625V18H8.125V15.5Z" fill="white" />
          <path d="M10 15.5H12.5V18H10V15.5Z" fill="white" />
          <path d="M16.875 15.5H14.375V18H16.875V15.5Z" fill="white" />
          <path d="M23.125 15.5H25.625V18H23.125V15.5Z" fill="white" />
          <path d="M34.375 15.5H31.875V18H34.375V15.5Z" fill="white" />
          <path d="M27.5 15.5H30V18H27.5V15.5Z" fill="white" />
          <path d="M21.25 15.5H18.75V18H21.25V15.5Z" fill="white" />
          <path d="M5.625 20H8.125V22.5H5.625V20Z" fill="white" />
          <path d="M30 20H10V22.5H30V20Z" fill="white" />
          <path d="M31.875 20H34.375V22.5H31.875V20Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 6.875C1.80969 6.875 1.25 7.43462 1.25 8.125V25.625C1.25 26.3154 1.80969 26.875 2.5 26.875H37.5C38.1903 26.875 38.75 26.3154 38.75 25.625V8.125C38.75 7.43462 38.1903 6.875 37.5 6.875H2.5ZM3.125 8.75V25H36.875V8.75H3.125Z"
            fill="white"
          />
          <path
            d="M20 35.8077L14.6564 30.5756L13.4998 31.7084L20.0024 38.0756L26.4996 31.7084L25.343 30.5756L20 35.8077Z"
            fill="white"
          />
        </svg>

        <svg
          style={{ cursor: "pointer" }}
          onClick={() => toggleAddNewCustomer()}
          width="40"
          height="47"
          viewBox="0 0 40 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66675 35.25C1.66675 30.8438 6.66675 30.8438 9.16675 27.9063C10.4167 26.4375 6.66675 26.4375 6.66675 19.0938C6.66675 14.1984 8.333 11.75 11.6667 11.75C15.0005 11.75 16.6667 14.1984 16.6667 19.0938C16.6667 26.4375 12.9167 26.4375 14.1667 27.9063C16.6667 30.8438 21.6668 30.8438 21.6668 35.25"
            stroke="white"
            stroke-linecap="round"
          />
          <path
            d="M36.6666 21.5417H23.3333"
            stroke="#2329D6"
            stroke-linecap="round"
          />
          <path
            d="M30.0001 13.7083V29.375"
            stroke="#2329D6"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div className="user-detail ">
        <div className="column justify-around">
          <p className="text-5 align-center justify-center">
            {" "}
            <Clock
              ticking={true}
              onChange={(e) => {
                const date = new Date(e);
                const hours = date.getSeconds();
                // console.log(hours);
                setMode(!mode);
                // console.log(mode);
              }}
              format={" MMMM D, YYYY, h:mm:ss A"}
            />{" "}
          </p>

          <div
            className="text-5 row align-center justify-between"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            <div
              className="text-5 align-center justify-center"
              style={{ fontWeight: "bold" }}
            >
              {" "}
              {user?.data?.name}&ensp;
            </div>
            <InternetStatus/>
            {offline?.invoices?.length > 0 && navigator.onLine ? (
              <div className="row mt-1 justify-end align-center ml-1">
                <div
                  onClick={() => syncData()}
                  className="row justify-center bg-1 align-center"
                  style={{ padding: 2, position: "relative" }}
                >
                  {syncing ? (
                    <div
                      style={{ width: 10, height: 10 }}
                      className="lds-ripple"
                    >
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <>
                      <FaSync color="#fff" size={25} />
                      {/* <span style={{color:"#fff",
                  position: "absolute",
                  bottom: 0
              }}>{offline?.invoices?.length}</span> */}
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          {/* <Detector
          render={ ({ online }) => {
            // syncData(online);
          
            return (
              <span onClick={() =>  syncData(online)} style={{fontSize:12,color:online ? "#49ff49": "#000",display:"inline-block"}}>
               {online ? (offline?.invoices?.length > 0 ? "Sync":"Synced") : (offline?.invoices?.length > 0 ? `UnSynced (${offline?.invoices?.length})`:"")}
              </span>
            )}
          }
          
        /> */}
        </div>
      </div>
      {/* <div className="time-weather">
      <img
          src={mode ? sunset : cloud}
          height="40"
          width="40"
          alt=""
          srcset=""
        />
      </div> */}
    </div>
  );
}

export default Navbar;
