import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonModal from "../../common/components/CommonModal";
import SyncInvoiceService from "../../utils/offline/invoiceApis"
import * as commonActions from "../../state/common/commonActions";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as userActions from "../../state/user/userActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import * as offlineActions from "../../state/offline/offlineActions";
import * as customerScreenActions from "../../state/customerScreen/customerScreenActions";

import { downloadFile, emptyCache, toggleFullScreen, togglePOSFullScreen, useQuery } from "../../utils/common";
import im1 from "./../../assets/im1.png";
import { FaArrowLeft, FaSync } from "react-icons/fa";

function OperationPageContainer() {
  // Confirm Logout
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [syncing, setSyncing] = useState(false);
  
  const toggleConfirmLogout = () => setShowConfirmLogout(!showConfirmLogout);
  
  const dispatch = useDispatch();
  const history = useHistory();
  
  const offline = useSelector(state => state.offline);
  // const [countOffline, setCountOffline] = useState(offline?.invoices?.length)

  const onOkConfirmLogout = async () => {
    await syncData();
    dispatch(customerScreenActions.toggleScreen(false));
    dispatch(commonActions.trashOff());
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    dispatch(userActions.emptyUser());
    dispatch(suspendActions.emptySuspend());
    history.push("/");
  };

  function openWindow() {

    var width = 5550;
    var left = 5600;
    var w = 5550;
    var h = 5600;
    var top = (window.screen.height / 2) - (h / 2);
    var url = 'customer-screen';
    var title = 'Customer Screen';

    left += Math.abs(window.screenX);
    
    console.log(window)
   
    window.open('/?screen=cs', 'windowName', 'resizable=1,scrollbars=1,fullscreen=0,height=100%,width=' + width + ' , height=' + h + ' , top=' + top + ', left=' + left + ', toolbar=0, menubar=0,status=1');
    return 0;

}

const syncData = async () => {
  if(offline?.invoices?.length > 0){
    downloadFile(offline?.invoices)
    setSyncing(true)
    await offline?.invoices.forEach(async (invoice) =>  {
   
      const response =  await SyncInvoiceService.addOrder(invoice);
      if (response.success) {
        await dispatch(offlineActions.deleteOfflineInvoice(invoice.id));
        // setCountOffline(countOffline-1)
      console.log("Success",invoice);
    }else{

    }
  });
  // setSyncing(false)
  // dispatch(offlineActions.emptyOfflineInvoices())
}
  if(offline?.customers?.length > 0){
    
  }
}


  return (
    <div className="home-container  bg-10 w-100">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Operation</h3>
      </div>
      <div className="modal-actions-lg row justify-center">
        <Link to="/home" className="modal-btn-lg row justify-center align-center bg-1 text-1">
        <FaArrowLeft color="#fff" /> &nbsp; Back
        </Link>
      </div>

      <div className="row justify-around align-left mt-2 label">
        <img src={im1} alt="" className="operation-img" />
        <div className="row justify-end" style={{ flexWrap:"wrap" ,width:"25%"}}>
          <Link to="/reprint" className="ml-1 row justify-center align-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/reprint.png"}
              height="100"
              width="100"
              alt="Reprint"
            />
          </Link>
          <Link to="/return" className="ml-1 row justify-center align-center">
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/rinvoice.png"}
              height="100"
              width="100"
              alt="Return Invoice"
            />
          </Link>
          <Link
            to="/sales-report"
            className="ml-1 row mt-1 justify-center align-center"
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/report.png"}
              height="100"
              width="100"
              alt="Report"
            />
          </Link>
          {/* <Link
            to="/stock-management"
            className="ml-1 row mt-1 justify-center align-center"
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/boxes.png"}
              height="100"
              width="100"
              alt="Stock Management"
            />
          </Link> */}
           <div className="row mt-1 justify-end align-center ml-1">
            <div
              onClick={() =>
              { 
              togglePOSFullScreen(true)
              dispatch(customerScreenActions.toggleScreen(true));
              var width = 1890;
              var left = 1500;
              var w = 1250;
              var h = 1600;
              var top = window.screen.height / 2 - h / 2;
              var url = "customer-screen";
              var title = "Customer Screen";
              left += Math.abs(window.screenX);
              window.open(
                "/?screen=cs",
                "windowName",
                "resizable=yes,scrollbars=yes,fullscreen=yes,height=100%,width=" +
                  width +
                  " , height=" +
                  h +
                  " , top=0,left=0, toolbar=0, menubar=0,status=1"
              );
              }
            }
              className="row justify-center align-center"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/customerscreen.png"}
                height="100"
                width="100"
              />
            </div>
          </div>
     
          <div className="row mt-1 justify-end align-center ml-1">
            <div
              onClick={() => toggleConfirmLogout()}
              className="row justify-center align-center"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/logout.png"}
                height="100"
                width="100"
                alt="Logout"
              />
            </div>
          </div>
          {offline?.invoices?.length > 0 && navigator.onLine ?
          <div className="row mt-1 justify-end align-center ml-1">
            <div
              onClick={() => syncData()}
              className="row justify-center bg-1 align-center"
             style={{padding:20,position:"relative"}} 
            >
              {/* <h3>{countOffline}</h3> */}
              {syncing ? 
              <div style={{width:60,height:60}} className="lds-ripple"><div></div><div></div></div>
              :
              <>
              <FaSync color="#fff" size={60} />
              <span style={{color:"#fff",
                  position: "absolute",
                  bottom: 0
              }}>{offline?.invoices?.length}</span>
              </>
              }
            </div>
          </div>
          :null}
  

         
          
        </div>
      </div>

      <CommonModal
        children={() => {}}
        show={showConfirmLogout}
        onOk={onOkConfirmLogout}
        title="Confirm Logout ?"
        toggleModal={toggleConfirmLogout}
      />
    </div>
  );
}

export default OperationPageContainer;
