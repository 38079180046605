import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function InternetStatus() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };
    }, []);

  return (
    <div
      className="text-5 align-center justify-center"
      style={{ fontWeight: "bold", flexDirection: "row" }}
    >
      {isOnline ? (
        <span
          style={{
            fontSize: 22,
            color: "#49ff49",
            display: "inline-block",
          }}
        >
          &#x25cf;
        </span>
      ) : (
        <span
          style={{
            fontSize: 22,
            color: "#000",
            display: "inline-block",
          }}
        >
          &#x25cf;
        </span>
      )}
    </div>
  );
}

export default InternetStatus;
