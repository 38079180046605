import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CommonModal from "../../common/components/CommonModal";
import CurrencyService from "../../api/CurrencyAPI";
import * as commonActions from "../../state/common/commonActions";
import * as orderActions from "../../state/order/orderActions";
import * as invoiceActions from "../../state/invoice/invoiceActions";
import * as offlineActions from "../../state/offline/offlineActions";
import NumPad from "../../common/components/NumPad";
import { getTens, n_format, n_round, randomGenerator } from "../../utils/common";
import { FaArrowLeft } from "react-icons/fa";
import OrderService from "../../api/OrderAPI";

function Tendor({ toggle, show, toggleConfirm, toggleInvoice }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customer = useSelector((state) => state.customer);
  const offline = useSelector((state) => state.offline);
  const user = useSelector(state => state.user);
  const invoice = useSelector(state => state.invoice);
  const [activeField, setActiveField] = useState("AMT");
  const [redeem, setRedeem] = useState(false);
  const [payBy, setPayBy] = useState("CASH");
  const [firstRender, setFirstRender] = useState(true);
  const [remainingAmount, setRemainingAmount] = useState(0)

  const KHM_CURRENCY_RATE = common?.currencies?.find(e => e?.code == "KHR")?.value;

  const [dinoms, setDinoms] = useState({
    USD: "0.00",
    KHR: "0.00",
  });

  const [dinomAmount, setDinomAmount] = useState("0.00");

  const dispatch = useDispatch();

  const [activeCurrency, setActiveCurrency] = useState("USD");

  // TODO Global Currency
  // const [USD_RATE?.value, setUSD_RATE?.value] = useState(4001);
  // const [KHM_RATE, setKHM_RATE] = useState(1);
  const KHR_RATE = common?.currencies[0];
  const USD_RATE = common?.currencies[1];

  const [amount, setAmount] = useState("0.00");
  const [calAmount, setCalAmount] = useState("0.00");

  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);
  const [redeemAmount, setRedeemAmount] = useState("0.00");
  const [redeemableAmount, setRedeemableAmount] = useState("");
  const [pTotalAmount, setPTotalAmount] = useState("0.00")
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setOrderAmount();
    setActiveCurrency("USD");
    if (!(order.products > 0)) {
      setDinomAmount("0.00");
      setRemainingAmount(0)
      setDinoms({
        USD: "0.00",
        KHR: "0.00",
      })
    }
  }, [order.products, show]);

  useEffect(() => {
    if (activeCurrency === "USD") {
      setAmount(n_format(calAmount));
    } else {
      // setAmount(amount * (USD_RATE?.value));
      setAmount(n_format(calAmount));
    }
    setActiveField("AMT")
    setFirstRender(true);
  }, [activeCurrency, show]);

  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = +pureQuantity + +e?.quantity;
      purePoints = +purePoints + (e?.quantity * e?.price * (e?.point || 0)) / 100;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
    setOrderPoints(purePoints);
    setAmount(n_format(pureTotalAmount - discount));
    setCalAmount(n_format(pureTotalAmount) - n_format(discount));
    setPTotalAmount(n_format(pureTotalAmount));
  };

  useEffect(() => {
    if (redeem) {
      setAmount("");
      setRedeemAmount("");
      setRedeemableAmount("")
      setActiveField("RDM")
    } else {
      setRedeemAmount("");
      setRedeemableAmount("")
      setOrderAmount();
    }
  }, [redeem, show]);

  useEffect(() => {
    setRedeem(false)
  }, [order?.customer_id])

  useEffect(() => {
    if (redeem) {
      setAmount(n_format(calAmount - redeemableAmount));
    } else {
      setRedeemAmount("");
      setRedeemableAmount("")
      setOrderAmount();
    }

  }, [redeemAmount, redeemableAmount, show]);


  const savetoDB = async (data) => {
    setIsSaving(true)

    console.log(data, "hello in SaveDB");
    try {
      const response = await OrderService.addOrder(data);
      console.log(response, "dsjndskjfnkdjsnfkjdsnk");
      if (response.success) {
        setIsSaving(false)
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error, "dsjbfjkfndkjnk");
      dispatch(offlineActions.addOfflineInvoice({
        id: offline?.invoices?.length + 1,
        data: { ...data, mode: "S" }
      }));
      setIsSaving(false);
    }

  }

  const onTenderOk = async () => {

    const amount2 = redeemAmount ? calAmount - redeemAmount : calAmount;
    dispatch(
      orderActions.setInvoice({
        amount,
        redeemAmount,
        dinomAmount,
        payBy,
      })
    );
    dispatch(
      orderActions.setPrinted(true)
    );

    toggleInvoice(toggleConfirm);
    toggle();
    setTimeout(() => {
      window.print();
    }, 100);
    const data = {
      order: order,
      customer: customer.data,
      user_id: user?.data?.id,
      amount: amount2,
      redeemAmount,
      currency: common?.current_currency,
      currency_rate: KHM_CURRENCY_RATE ,
      orderPoints,
      payBy: payBy,
      branchId: user?.data?.branch?.id,
      invoiceId: invoice?.lastInvoiceId,
      dinomAmount: dinomAmount,
      pAmount: pTotalAmount,
      random_id: randomGenerator(10),
      mode: "N"
    };
    const saved = await savetoDB({ ...data });
    setDinomAmount("0.00");
    setDinoms({
      USD: "0.00",
      KHR: "0.00",
    });

  };
  const renderTender = () => {
    const changePercent = (value) => {
      let a = amount;

      if (activeField === "AMT") {
        if (firstRender) {
          setAmount(value);
          setFirstRender(false);
          return;
        }
        if (value === "." && amount?.toString()?.includes(".")) {
          return;
        }

        if (n_format(amount) > 0.0) {
        } else {
          if (!amount?.toString()?.includes(".")) a = "";
        }

        if (amount?.toString()?.includes(".")) {
          setAmount(a + "" + value);
        } else {
          setAmount(a + "" + value);
        }
      } else if (activeField === "RDM") {
        if (firstRender) {
          //setRedeemAmount(value);
          console.log(value, 23);
          setRedeemableAmount(value)
          setFirstRender(false);
          return;
        }
        let r = redeemableAmount;
        console.log(value, 232);
        console.log(redeemableAmount);
        if (value === "." && redeemableAmount?.toString()?.includes(".")) {
          return;
        }

        if (n_format(redeemableAmount) > 0.0) {
        } else {
          if (!redeemableAmount?.toString()?.includes(".")) r = "";
        }

        if (redeemableAmount?.toString()?.includes(".")) {
          setRedeemableAmount(r + "" + value);
        } else {
          setRedeemableAmount(r + "" + value);
        }
        // if (n_format(redeemAmount + "" + value) <= customer?.data?.min_redeem) {
        //   setRedeemAmount(customer?.data?.min_redeem);
        // } else
        // if (n_format(r + "" + value) > customer?.data?.point) {
        //   setRedeemableAmount(n_format(customer?.data?.point));
        // }
      }
    };
    const changeCalculate = (currency = "USD") => {
      if (activeField === "AMT") {
        if (amount && n_format(amount) > 0) {
          const newDs = {
            ...dinoms,
            [activeCurrency]: amount,
          };
          setDinoms(newDs);
          setAmount("");
        }
      }
      if (activeField === "RDM") {
        console.log(redeemableAmount, "njkn");
        console.log(calAmount);
        console.log(customer?.data?.point);
        if (n_format(redeemableAmount) <= calAmount && n_format(redeemableAmount) <= customer?.data?.point) {


          setRedeemAmount(redeemableAmount)
        } else {

        }
      }
    };

    const getTotalDinomsUSD = (currency = "USD") => {
      if (currency) {
        const USDAmount = n_format(dinoms?.USD);
        const KHRAmount = n_format(dinoms?.KHR) / n_format(USD_RATE?.value);
        setDinomAmount(n_format(+USDAmount + +KHRAmount));
        if (n_format(+USDAmount + +KHRAmount + +redeemAmount) > 0) {
          if (n_format(totalAmount - orderDiscount - redeemAmount) > n_format(+USDAmount + +KHRAmount + +redeemAmount)) {
            setRemainingAmount(n_format(totalAmount - orderDiscount - redeemAmount - (+USDAmount + +KHRAmount + +redeemAmount)));
          } else {
            setRemainingAmount(0)
          }
        }

        return n_format(+USDAmount + +KHRAmount + +redeemAmount);
      } else {
        return "0.00";
      }
    };

    const getTotalDinomsKHR = (currency = "KHR") => {
      if (currency) {
        if (dinoms.length) {
          const filterArray = dinoms.filter((e) => e.currency === currency);
          if (filterArray.length > 1) {
            const sum = filterArray?.reduce((a, b) => +a.amount + +b.amount);
            return +sum;
          } else {
            if (filterArray.length === 1) {
              return +filterArray[0]?.amount;
            } else return "0.00";
          }
        } else {
          return "0.00";
        }
      } else {
        return "0.00";
      }
    };

    const deleteDinoms = (currency) => {
      const newD = {
        ...dinoms,
        [currency]: "0.00",
      };
      setDinoms(newD);
    };

    const changePercentDelete = (allDelete) => {
      if (activeField === "AMT") {
        if (allDelete) {
          setAmount("");
        } else {
          setAmount(amount?.toString()?.slice(0, -1));
        }
      } else if (activeField === "RDM") {
        if (allDelete) {
          setRedeemAmount("");
          setRedeemableAmount("")
        } else {
          setRedeemableAmount(redeemableAmount?.toString()?.slice(0, -1));
        }
      }
    };

    const toggleRedeem = () => {
      setRedeemAmount("");
      setRedeemableAmount("")
      setActiveField(activeField !== "RDM" ? "RDM" : "AMT");
      setRedeem(!redeem);
    };

    const onPrint = () => {
      window.print();
    };

    return (
      <div className="w-100 bg-10">
        <div className="modal-header-lg row bg-1 align-center justify-center">
          <h3 style={{ color: "var(--white)" }}>Tender</h3>
        </div>
        <div className="modal-actions-lg row justify-center">
          {isSaving ?
            <div style={{ width: 60, height: 60 }} className="lds-ripple"><div></div><div></div></div>
            :
            <div className="modal-btn-lg bg-6 text-1" onClick={onTenderOk}>
              Print
          </div>
          }
          <div className="modal-btn-lg bg-1 text-1 row justify-center align-center" onClick={toggle}>
            <FaArrowLeft color="#fff" /> &nbsp; Back
          </div>
        </div>
        <div
          className="row justify-between"
          style={{ marginLeft: 10, marginTop: 35, marginBottom: 10 }}
        >
          {/* <div className="row">
            <div
              onClick={() => setActiveCurrency("USD")}
              className={`modal-btn-lg  bg-3 text-1 ${
                activeCurrency === "USD" ? "active-btn" : ""
              }`}
            >
              Cash in USD
            </div>
            <div
              onClick={() => setActiveCurrency("KHR")}
              className={`modal-btn-lg bg-1 text-1 ${
                activeCurrency === "KHR" ? "active-btn" : ""
              }`}
            >
              Cash in Riel
            </div>
          </div> */}
          <div
            className="row justify-end align-center"
            style={{
              marginLeft: 10,
              justifySelf: "flex-end",
              alignSelf: "flex-end",
              marginTop: 0,
              marginRight: 10,
            }}
          >

          </div>
        </div>

        <div
          className="w-100 row "
          style={{ paddingRight: 10, paddingLeft: 10 }}
        >
          <div className="w-25 ">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">Member Point Area</span>
            </div>
            <div className="bg-2 point-section">
              {order.customer_id ? (
                <>
                  <div className="customer-btn  bg-6 text-1">
                    {customer?.data?.phone}
                  </div>
                  <div className="customer-btn  bg-3 text-1">
                    Current Points : {n_format(customer?.data?.point ?? 0)}
                  </div>
                  <div className="customer-btn  bg-4 text-1" style={{ fontSize: 13 }}>
                    Points from Order : {n_format(orderPoints)}
                  </div>
                  {redeem ? (
                    <>
                      <div
                        className="customer-btn  bg-3 text-1"
                        style={{ fontSize: 12 }}
                      >
                        Remaining Points :{" "}
                        {n_format(+customer?.data?.point - +redeemAmount)}
                      </div>
                      <div
                        onClick={
                          customer?.data?.point >= customer?.data?.min_redeem
                            ? toggleRedeem
                            : () => { }
                        }
                        className={`customer-btn  text-1 ${customer?.data?.point >= customer?.data?.min_redeem
                          ? "bg-6"
                          : "bg-5"
                          }`}
                      >
                        Cancel Redeem
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={
                        customer?.data?.point >= customer?.data?.min_redeem
                          ? toggleRedeem
                          : () => { }
                      }
                      className={`customer-btn  text-1 ${customer?.data?.point >= customer?.data?.min_redeem
                        ? "bg-6"
                        : "bg-5"
                        }`}
                      style={{ fontSize: 12 }}
                    >
                      Redeem ( Min. Amt {customer?.data?.min_redeem ?? 0})
                    </div>
                  )}
                </>
              ) : (
                <div className="customer-btn bg-1 text-1">Walk In</div>
              )}

            </div>

            <div className="column " style={{ width: 200, margin: 15 }}>
              <div className="row justify-between">
                <div className="text-3">Sub Total</div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;$ {n_format(totalAmount)}
                </div>
              </div>
              <div className="row justify-between">
                <div className="text-3">Discount</div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;$ {n_format(orderDiscount)}
                </div>
              </div>
              {redeem ? (
                <div className="row justify-between">
                  <div className="text-3">Redeem Points</div>
                  <div className="text-3">
                    {" "}
                    : &ensp;&ensp;&ensp;$ {redeemAmount === "" ? "0" : n_format(redeemAmount)}
                  </div>
                </div>
              ) : null}
              <div className="row justify-between">
                <div className="text-3">Grand Total</div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;${" "}
                  {n_format(totalAmount - orderDiscount - redeemAmount)}
                </div>
              </div>
              <div className="row justify-between">
                <div className="text-3">Amount in KHR</div>
                <div className="text-3">
                  : {USD_RATE?.symbol ?? "$"}{" "}
                  {n_round(n_format(totalAmount - orderDiscount - redeemAmount) *
                    USD_RATE?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="w-25">
            <div className="">

              <div className="row justify-between label">
                <div className="text-2">
                  USD
                </div>

                <div className="text-2">Use Base Currency</div>

              </div>
              <input
                type="text"
                value={activeCurrency === "USD"
                  ? (amount)
                  : n_format((amount) * (KHR_RATE?.value / USD_RATE?.value))}
                onChange={(e) => setAmount(e.target.value)}
                onClick={() => {
                  setActiveCurrency("USD")
                  setActiveField("AMT");
                  setAmount("");
                  setFirstRender(true);
                }}
                onFocus={() => {
                  setActiveCurrency("USD")
                  setActiveField("AMT");
                  setAmount("");
                  setFirstRender(true);
                }}
                className={`numpad-input-2 border-bg-1 ${activeCurrency === "USD" && activeField === "AMT" ? "active-btn  active-field" : ""}`}
              />
              <div className="ml-1 text-2">&nbsp;{remainingAmount > 0 ? `Remaining Amount in USD ${remainingAmount}` : ""}</div>
              <div className="row justify-between label">
                <div className="text-2">
                  {" "}
                    Riel
                </div>
                {activeCurrency === "KHR" ? (
                  <div className="text-2">Use Base Currency</div>
                ) : (
                  <div className="text-2">1 USD = {USD_RATE?.value} KHR</div>
                )}
              </div>
              <input
                type="text"
                onChange={(e) => setAmount(e.target.value)}
                value={
                  activeCurrency === "USD"
                    ? ((amount) * (USD_RATE?.value / KHR_RATE?.value))
                    : (amount)
                }
                onClick={() => {
                  setActiveCurrency("KHR")
                  setActiveField("AMT");
                  setAmount("");
                  setFirstRender(true);
                }}
                onFocus={() => {
                  setActiveField("AMT");
                  setActiveCurrency("KHR")
                  setAmount("");
                  setFirstRender(true);
                }}
                className={`numpad-input-2 border-bg-1 ${activeCurrency !== "USD" && activeField === "AMT" ? "active-btn active-field" : ""}`}
              />
              <div className="ml-1 text-2">&nbsp;{remainingAmount > 0 ? `Remaining Amount in KHR ${remainingAmount * n_format(USD_RATE?.value)}` : ""}</div>
            </div>
            {redeem ? (
              <>
                <div className="row justify-between label">
                  <div className="text-2" style={{ fontSize: 9 }}>
                    Redeem Amount
                    </div>

                  <div className="text-2">
                    Min: {0.01} - Max: {n_format(totalAmount - orderDiscount)}
                    { }
                  </div>
                </div>
                <input
                  type="text"
                  value={redeemableAmount}
                  onChange={(e) => setRedeemableAmount(e.target.value)}
                  onClick={() => {
                    setActiveField("RDM");
                    setRedeemAmount("");
                    setRedeemableAmount("")
                    setFirstRender(true);
                  }}
                  onFocus={() => {
                    setActiveField("RDM");
                    setRedeemableAmount("");
                    setFirstRender(true);
                  }}
                  className={`numpad-input-2  border-bg-6  ${activeField === "RDM" ? "active-field" : ""
                    }`}
                />
              </>
            ) : null}
            <NumPad
              changeCalculate={changeCalculate}
              changePercent={changePercent}
              changePercentDelete={changePercentDelete}
            />
          </div>

          <div className="w-50">
            <div className="w-100  justify-between row">
              <div className="w-100">
                <div className="btn t-header row justify-center align-center t-center bg-1">
                  <span className="text-1 ">Currency</span>
                </div>
              </div>
              <div className="w-100">
                <div className="btn t-header row justify-center align-center t-center bg-1">
                  <span className="text-1 ">Type</span>
                </div>
              </div>
              <div className="w-100">
                <div className="btn t-header row justify-center align-center t-center bg-1">
                  <span className="text-1 ">Receive</span>
                </div>
              </div>
              <div className="w-100">
                <div className="btn t-header row justify-center align-center t-center bg-1">
                  <span className="text-1 ">Delete</span>
                </div>
              </div>
            </div>
            <div className="bg-2 dinom-section">
              <div
                className="w-100  justify-between row "
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                <div className="w-100 ">
                  <div className="btn t-header row justify-center align-center t-center bg-7 ">
                    <span className="text-1 ">USD</span>
                  </div>
                </div>
                <div className="w-100 ">
                  <div className="btn t-header row justify-center align-center t-center bg-7">
                    <span className="text-1 ">GRAND TOTAL</span>
                  </div>
                </div>
                <div className="w-100 ">
                  <div className="btn t-header row justify-center align-center t-center bg-7">
                    <span className="text-1 ">
                      {n_format(calAmount * KHR_RATE?.value)}
                    </span>
                  </div>
                </div>
                <div className="w-100 " onClick={() => setOrderAmount()}>
                  <div className="btn t-header row justify-center align-center t-center bg-4">
                    <span className="text-1 ">X</span>
                  </div>
                </div>
              </div>
              {redeemAmount ? (
                <div className="w-100  justify-between row ">
                  <div className="w-100 label-1">
                    <div className="btn t-header row justify-center align-center t-center bg-6 ">
                      <span className="text-1 ">{"REDEEM"}</span>
                    </div>
                  </div>
                  <div className="w-100 label-1">
                    <div className="btn t-header row justify-center align-center t-center bg-6">
                      <span className="text-1 ">POINTS</span>
                    </div>
                  </div>
                  <div className="w-100 label-1">
                    <div className="btn t-header row justify-center align-center t-center bg-6">
                      <span className="text-1 ">{redeemAmount}</span>
                    </div>
                  </div>
                  <div className="w-100 label-1" onClick={() => toggleRedeem()}>
                    <div className="btn t-header row justify-center align-center t-center bg-1">
                      <span className="text-1 ">Delete</span>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="w-100  justify-between row ">
                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6">
                    <span className="text-1 ">{"USD"}</span>
                  </div>
                </div>
                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6">
                    <span className="text-1 ">CASH</span>
                  </div>
                </div>
                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6 ">
                    <span className="text-1 ">{n_format(dinoms?.USD)}</span>
                  </div>
                </div>
                <div
                  className="w-100 label-1"
                  onClick={() => deleteDinoms("USD")}
                >
                  <div className="btn t-header row justify-center align-center t-center bg-1">
                    <span className="text-1 ">Delete</span>
                  </div>
                </div>
              </div>
              <div className="w-100  justify-between row ">
                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6">
                    <span className="text-1 ">{"KHR"}</span>
                  </div>
                </div>
                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6">
                    <span className="text-1 ">CASH</span>
                  </div>
                </div>

                <div className="w-100 label-1">
                  <div className="btn t-header row justify-center align-center t-center bg-6 ">
                    <span className="text-1 ">{dinoms?.KHR}</span>
                  </div>
                </div>
                <div
                  className="w-100 label-1"
                  onClick={() => deleteDinoms("KHR")}
                >
                  <div className="btn t-header row justify-center align-center t-center bg-1">
                    <span className="text-1 ">Delete</span>
                  </div>
                </div>
              </div>
              <div className="w-60  justify-center">
                <div className="w-100 label-1" style={{ marginTop: 20 }}>
                  <div className="row justify-center align-center t-center bg-1 ">
                    <span
                      style={{
                        fontSize: 15,
                        color: "var(--white)",
                        padding: 10,
                        fontFamily: "Arial",
                      }}
                    >
                      TOTAL CASH IN {"USD"} : $ {getTotalDinomsUSD()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-60 row  justify-between">
                <div
                  onClick={() => setPayBy("CASH")}
                  className={`w-100 btn label-1 ${payBy === "CASH" ? "" : "active-btn-by"
                    }`}
                  style={{ marginTop: 20 }}
                >
                  <div className={`row justify-center align-center t-center  ${payBy === "CASH" ? "bg-1" : "active-btn-by bg-5"
                    }`}>
                    <span
                      style={{
                        fontSize: 15,
                        color: "var(--white)",
                        padding: 10,
                        fontFamily: "Arial",
                      }}
                    >
                      Pay by Cash
                    </span>
                  </div>
                </div>
                <div className="w-100  btn label-1" style={{ marginTop: 20 }}>
                  <div
                    onClick={() => setPayBy("CARD")}
                    className={`row justify-center align-center t-center  ${payBy === "CARD" ? "bg-3" : "active-btn-by bg-5"
                      }`}
                  >
                    <span
                      style={{
                        fontSize: 15,
                        color: "var(--white)",
                        padding: 10,
                        fontFamily: "Arial",
                      }}
                    >
                      Pay By Card
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="column " style={{ width: 200, margin: 15 }}>
              <div className="row justify-between">
                <div className="text-3">Change:</div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;
                  {n_format(+dinomAmount - +calAmount + +redeemAmount)}
                </div>
              </div>
              <div className="row justify-between">
                <div className="text-3">
                  $ {n_format(+dinomAmount - +calAmount + +redeemAmount)}:
                </div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;
                  {USD_RATE?.symbol ?? "$"}{" "}
                  {n_round(n_format(+dinomAmount - +calAmount + +redeemAmount) *
                    USD_RATE?.value)}
                </div>
              </div>


              <div className="row justify-between">
                <div className="text-3">
                  $
                  {n_format(
                  ((+dinomAmount - +calAmount + +redeemAmount) % 10)
                )}
                </div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp;{USD_RATE?.symbol} {" "}
                  {n_round(n_format(
                    (
                      (+dinomAmount - +calAmount + +redeemAmount) % 10
                    ) * USD_RATE?.value
                  ))}
                </div>
              </div>
              <div className="row justify-between">
                <div className="text-3">
                  ${n_format((+dinomAmount - +calAmount + +redeemAmount) % 1)}
                </div>
                <div className="text-3">
                  {" "}
                  : &ensp;&ensp;&ensp; {USD_RATE?.symbol}{" "}
                  {n_round(n_format(
                    ((+dinomAmount - +calAmount + +redeemAmount) % 1) *
                    USD_RATE?.value
                  ))}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    console.log("in tendor")
    getCurrencies();
  }, []);

  const getCurrencies = async () => {
    const data = await CurrencyService.getCurrencies();
    console.log(data,"Curren")
    dispatch(commonActions.setCurrencies(data));
  };

  return (
    <CommonModal
      full
      children={renderTender}
      isSaving={isSaving}
      show={show}
      onOk={!isSaving ? onTenderOk : () => { }}
      toggleModal={toggle}
    />
  );
}

export default Tendor;
