import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarcodeReader from "react-barcode-reader";
import ProductService from "../../../api/ProductAPI";
import Clock from "react-live-clock";
import { useLocation } from "react-router";
import * as commonActions from "../../../state/common/commonActions";
import * as stockActions from "../../../state/stock/stockActions";
import KeyboardIcon from "../../../assets/svgs/KeyboardIcon";
import useOrders from "../../../hooks/useOrders";
import FullScreenLoader from "../../../common/components/FullScreenLoader";
import Keyboard from "react-simple-keyboard";
import layouts, { defaultName } from "../../../utils/keyboardLayouts";
import InternetStatus from "../../../pages/HomePage/components/InternetStatus";

const Navbar = ({ title, entryType = "in" }) => {
  // Props
  const location = useLocation();
  const inputRef = useRef(null);

  // States
  const [searchText, setSearchText] = useState("");
  const [usingScanner, setUsingScanner] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const [getOrders] = useOrders();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default");

  const setSearchTextFromVK = () =>
    common.activeField == "SM_navbar_search" && setSearchText(common.text);
  const searchViaVK = () =>
    common.activeField == "SM_navbar_search" && getProducts(common.text);

  useEffect(() => {
    setSearchTextFromVK();
  }, [common.activeField, common.text]);

  useEffect(() => {
    searchViaVK();
  }, [common.enter]);

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };
  // Functions
  const handleAdd = (item) => {
    console.log({ item });
    dispatch(stockActions.addProduct(item));
  };

  const handleError = (e) => {
    alert(JSON.stringify(e));
  };

  const handleScan = async (code) => {
    console.log({ code });
    inputRef?.current && inputRef?.current?.focus();
    setSearchText("");
    setUsingScanner(true);
    setSearchText(code);
    if (code) {
      getProducts(code);
    }
  };

  const getProducts = async (barcode) => {
    setShowKeyboard(false);
    dispatch(commonActions.setLoading(true));
    console.log(barcode, "Inside Get Products Code Stock");
    try {
      const product = await ProductService.searchProducts({
        barcode,
        entry_type: entryType,
        with: "logs",
        limit: 1,
      });
      console.log(product, "Response from Get products");
      dispatch(commonActions.setLoading(false));
      if (product.data?.length > 0) {
        handleAdd(product.data[0]);
        setSearchText("");
      } else {
        dispatch(stockActions.emptyProducts());
        setSearchText("");
      }
    } catch (error) {
      dispatch(commonActions.setLoading(false));
      console.warn(error);
    }
  };

  const searchOrder = async () => {
    if (searchText) {
      getOrders({ orderNumber: searchText });
    }
  };

  // Side Effects

  return (
    <>
      <div className="bg-1 navbar-1">
        <div className="search-bar">
          <div
            className="column justify-center"
            style={{ position: "relative" }}
          >
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <input
              ref={inputRef}
              type="text"
              value={searchText}
              style={{
                fontSize: 18,
                paddingLeft: 10,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
              onClick={() => {
                dispatch(commonActions.emptyText());
                dispatch(commonActions?.setActiveField("SM_navbar_search"));
                dispatch(commonActions?.toggleKeyboard(true));
              }}
              onInput={(e) => {
                setSearchText(e.target.value);
                common.activeField == "SM_navbar_search" &&
                  dispatch(commonActions.setText(e.target.value));
              }}
              onKeyPress={(e) => {
                if (e.code == "Enter") {
                  getProducts(searchText);
                }
              }}
            />

            <p className="text-1">Barcode</p>
          </div>

          <KeyboardIcon
            onClick={() => {
              dispatch(commonActions?.toggleKeyboard(false));
            }}
          />
        </div>

        <h3
          style={{ color: "var(--white)", marginLeft: "-15%" }}
        >{`${title}`}</h3>

        <div className="user-detail ">
          <div className="column justify-around">
            <p className="text-5 align-center justify-center">
              {" "}
              <Clock ticking={true} format={" MMMM D, YYYY, h:mm:ss A"} />{" "}
            </p>

            <div
              className="text-5 row align-center justify-between"
              style={{ fontWeight: "bold" }}
            >
              <div
                className="text-5 align-center justify-center"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                {user?.data?.name}&ensp;
              </div>
              <InternetStatus />
            </div>
          </div>
        </div>
      </div>
      {common?.showLoading ? <FullScreenLoader /> : null}
    </>
  );
};

export default Navbar;
