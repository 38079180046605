import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonLg from "../../common/components/ButtonLg";
import { n_format, toggleFullScreen } from "../../utils/common";
import HomeFooter from "./components/HomeFooter";
import ProductsTable from "./components/ProductsTable";
import * as customerScreenActions from "../../state/customerScreen/customerScreenActions";
import PromotionService from "../../api/PromotionAPI";
import { updateScreens , openSlideWindow, toggleFullscreen} from "../../utils/multiscreen";
import CommonModal from "../../common/components/CommonModal";


function HomePageContainer() {
 
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customerScreen = useSelector((state) => state.customerScreen);
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if(!customerScreen.status){
      dispatch(customerScreenActions.toggleScreen(false));
      window.close();
    }
  
  }, [customerScreen.status])
 
  // TODO Global Currency
  const USD_RATE = common?.currencies[1];

  const [amount, setAmount] = useState("0.00");
  const [calAmount, setCalAmount] = useState("0.00");
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [promotions, setPromotions] = useState([]);
  const [standBy, setStandBy] = useState(null);
  const [slides, setSlides] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [left, setleft] = useState(null);
  const [top, setTop] = useState(null);
  const [currentLeftImage, setCurrentLeftImage] = useState(0);
  const [currentTopImage, setCurrentTopImage] = useState(0)
  const [showFullScreen, setShowFullScreen] = useState(true)

  const toggleShowFullScreen = () => setShowFullScreen(!showFullScreen);

  useEffect(() => {
    setOrderAmount();
  }, [order.products]);


  const onOkShowFullScreen = () => {
     toggleFullScreen();
    toggleShowFullScreen();
  }

  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      pureQuantity = +pureQuantity + +e?.quantity;
      purePoints = +purePoints + (e?.quantity * e?.price * (e?.point || 0)) / 100;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setAmount(n_format(pureTotalAmount - discount));
    setOrderDiscount(discount);
    setCalAmount(n_format(pureTotalAmount) - n_format(discount));
  };

  useEffect(() => {
    getPromotions();
    // if(top?.type === "I" &&  top?.data?.length > 0 ){
    //   const topInterval = setInterval(() => {
    //     if(currentTopImage >= 0 && currentTopImage < left?.data?.length - 1)
    //     {
    //       setCurrentTopImage(currentTopImage+1)
    //     }else{
    //       setCurrentTopImage(0)
    //     }
    //   }, 3000);
    // }
    //togglePOSFullScreen();
   //toggleFullScreen();
  // updateScreens();
   //toggleFullscreen(1);
   //openSlideWindow();
  }, []);


  useEffect(() => {
    if(left?.type === "I" && left?.data?.length > 0){
     setTimeout(() => {
        if(currentLeftImage >= 0 && currentLeftImage < left?.data?.length - 1)
        {
          setCurrentLeftImage(currentLeftImage+1)
        }else{
          setCurrentLeftImage(0)
        }
        console.log(currentLeftImage >= 0 && currentLeftImage < left?.data?.length - 1);
      }, 3000);
      }
  }, [currentLeftImage,left])

  useEffect(() => {
    if(top?.type === "I" && top?.data?.length > 0){
     setTimeout(() => {
        if(currentTopImage >= 0 && currentTopImage < top?.data?.length - 1)
        {
          setCurrentTopImage(currentTopImage+1)
        }else{
          setCurrentTopImage(0)
        }
        console.log(currentTopImage >= 0 && currentTopImage < top?.data?.length - 1);
      }, 3000);
      }
  }, [currentTopImage,top])



  const getPromotions = async () => {
    const data = await PromotionService.getPromotions();
    if(data?.length > 0){
    //  document.documentElement.requestFullscreen();
      // ref?.current?.requestFullscreen()
    

      setPromotions(data);
      console.log(data,"njnknknkkm");
      const standby = data.find(item => item.position === "R");
      if(standby){
        if( standby.type === "I"){

          setStandBy({
            data:standby.image,
            type:standby.type 
          })
        }else{
          setStandBy({
            data:standby.video,
            type:standby.type 
          })
        }
      }
      const left = data.find(item => item.position === "L");
      if(left){
        if( left.type === "I"){

          setleft({
            data:left.image?.split(","),
            type:left.type 
          })
        }else{
          setleft({
            data:left.video,
            type:left.type 
          })
        }
      }

      const top = data.find(item => item.position === "T");
      if(top){
        if( top.type === "I"){

          setTop({
            data:top.image?.split(","),
            type:top.type 
          })
        }else{
          setTop({
            data:top.video,
            type:top.type 
          })
        }
      }
      const slides = data.filter(e=> e?.type === "I").map(e => ({data:e?.data,title:e?.title}));
      setSlides([...slides]);
    }
  }
  
  return (
    <div className="customer-container" ref={ref}>
      <div className="row" style={{width:"100%",marginBottom:20,height:"30vh"}}>

   <div class="shadow-1">
    <h4 style={{maxWidth:"32vw",marginLeft:"1vw",color:"red",textAlign:"center",marginTop:20}} >{top?.title}</h4>
    {/* <img src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${e.data}`} style={{height:"30vh",maxWidth:"32vw",marginLeft:"1vw"}} /> */}
    {top?.type === "V" ? <video autoPlay={true} muted loop={true}  src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${top.data}`} style={{height:"100%",width:"100%"}} />:null }
    {top?.type === "I" ? <img src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${top.data[currentTopImage]}`} style={{height:"30vh",maxWidth:"32vw",marginLeft:"1vw"}} />:null }


      </div>
      </div>
      <div className="customer-video">
      {left?.type=== "V" ? <video autoPlay={true} muted loop={true}  src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${left.data}`} style={{height:"auto",width:"100%"}} />:null}
      {left?.type==="I" ? <img  src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${left.data[currentLeftImage]}`} style={{height:"90%",width:"100%"}} />:null}
      </div>
      {order?.products?.length > 0 ? 
      !order?.invoice?.dinomAmount ? (
        <div className="customer-data" style={{height:0}}>
          <ProductsTable isCustomer />
        </div>
      ) : (
        <div
          className=""
          style={{ width: "60%", right: 0, position: "absolute", bottom: 0 }}
        >
          <ButtonLg
            textStyle={{ fontSize: 40 }}
            style={{ height: n_format(orderDiscount) > 0 ? "107px" : "143px" }}
            titleSize={20}
            bg="bg-5"
            text="text-2"
            title="Total"
            value={"$" + n_format(amount)}
          />
          {n_format(orderDiscount) > 0 ? (
            <ButtonLg
              textStyle={{ fontSize: 40 }}
              style={{ height: "107px" }}
              titleSize={20}
              bg="bg-5"
              text="text-2"
              title="Discount"
              value={"$" + n_format(orderDiscount)}
            />
          ) : null}
          <ButtonLg
            textStyle={{ fontSize: 40 }}
            style={{ height: n_format(orderDiscount) > 0 ? "107px" : "143px" }}
            titleSize={20}
            bg="bg-5"
            text="text-2"
            title="Paid"
            value={"$" + n_format(order?.invoice?.dinomAmount)}
          />

          <ButtonLg
            textStyle={{ fontSize: 40 }}
            style={{ height: n_format(orderDiscount) > 0 ? "107px" : "143px" }}
            titleSize={20}
            bg="bg-6"
            title="Change"
            value={
              "$" +
              n_format(
                +order?.invoice?.dinomAmount -
                  +calAmount +
                  +order?.invoice?.redeemAmount
              )
            }
          />
          <p>1$ = ៛ {USD_RATE?.value}</p>
        </div>
      )
      : <div className="stand-by">
        {standBy?.type === "I"?  <img src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${standBy.data}` } style={{height:"90%",width:"100%"}} alt="RightImage" />: null}
        {standBy?.type === "V"? <video src={`${process.env.REACT_APP_URL_IMAGE}/promotion/${standBy.data}`} style={{height:"100%",width:"100%"}} />: null}
      </div> 
      }
       {/* <img src={`${process.env.PUBLIC_URL}/assets/customer/right.png`} style={{height:"90%",width:"100%"}} alt="RightImage" /> */}
      {!order?.invoice?.dinomAmount && order?.products?.length >0 ? <HomeFooter isCustomer /> : null}
      <CommonModal
        children={() => {}}
        show={showFullScreen}
        onOk={onOkShowFullScreen}
        cancel={false}
        title="Move Screen"
      />
    </div>
  );
}

export default HomePageContainer;
