import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import * as invoiceActions from "../../state/invoice/invoiceActions";
import { Link } from "react-router-dom";
import InvoiceService from "../../api/InvoiceAPI";
import InvoiceModal from "./components/InvoiceModal";
import { FaArrowLeft } from "react-icons/fa";

function ReprintPageContainer() {
  const children = <h1>Hello</h1>;

  // Load Suspend Data
  const suspend = useSelector((state) => state.suspend);
  const order = useSelector((state) => state.order);
  const user = useSelector((state) => state.user);
  const invoice = useSelector((state) => state.invoice);


  const [text, setText] = useState("");
  const [from, setFrom] = useState(new Date().toISOString().split('T')[0]);
  const [to, setTo] = useState(new Date().toISOString().split('T')[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    setText("")
  }, [from, to])
  

  const dispatch = useDispatch();

  useEffect(() => {
    if (suspend?.data?.length > 0) {
      const activeSuspend = suspend?.data?.find(
        (e) => e.id === suspend?.activeId
      );
      if (activeSuspend) {
        dispatch(orderActions.fillOrder(activeSuspend?.order));
        dispatch(customerActions.fillCustomer(activeSuspend?.customer));
        dispatch(suspendActions.deleteSuspend(suspend?.activeId));
      }
    }
  }, [suspend.activeId]);

  const [invoices, setInvoices] = useState([]);

  const nextProducts = async (query = '') => {
    try {
      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id,user?.data?.id, null, currentPage + 1);
      const data = data2?.data?.invoices;

      console.log(data);
      if (data?.data?.length > 0) {
        setCurrentPage(data.current_page);
        setInvoices(data.data);
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }

  const prevProducts = async (query = '') => {
    try {

      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id,user?.data?.id, null, currentPage - 1);
      const data = data2?.data?.invoices;
      console.log(data);
      if (data?.data?.length > 0) {
        setCurrentPage(data.current_page);
        setInvoices(data.data);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

    }

  }





  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async (query = text) => { 
       setSelected(null)
    dispatch(invoiceActions.setActiveInvoice(null))
    try {
      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices(query, from, to, null, null, user?.data?.branch?.id,user?.data?.id, null, 1);
      const data = data2.data.invoices;
      console.log(data);
      if (data.data.length > 0) {
        setInvoices(data.data);
      } else {
        setInvoices([])
      }
      setIsLoading(false)

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };
  const [selected, setSelected] = useState(null);

  const [showInvoice, setShowInvoice] = useState(false);

  const toggleInvoice = () => {
    setShowInvoice(!showInvoice)
  }

  return (
    <div className="home-container bg-10">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Reprint</h3>
      </div>
      <div className="modal-actions-lg row justify-center">
       {invoices.length > 0  && invoice?.activeInvoice && <div onClick={() => {
          toggleInvoice()
          setTimeout(() => {
            window.print();
          }, 100);
        }} className="modal-btn-lg bg-6 text-1">
          Print
        </div>
}
        <Link to="/home" className="modal-btn-lg row justify-center align-center bg-1 text-1">
          <FaArrowLeft color="#fff" /> &nbsp; Back
        </Link>
      </div>
      <Navbar next={nextProducts} prev={prevProducts} getInvoices={getInvoices} text={text} setText={setText} to={to} from={from} setTo={setTo} setFrom={setFrom} />
      <ProductsTable
        selected={selected}
        setSelected={setSelected}
        invoices={invoices}
        isLoading={isLoading}
      />
      <InvoiceModal toggle={toggleInvoice} invoice={selected} show={showInvoice} reprint={true} />
    </div>
  );
}

export default ReprintPageContainer;
