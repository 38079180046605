import React from 'react'
import { Navbar, ProductRow, ProductTable, Footer } from '../../common/components/stockManagement'
import { t } from '../../utils/common'

function ManualStockOutContainer() {
	return (
		<div
			style={{
				marginBottom: '100px',
			}}
		>
			<Navbar title={'Manual Stock out'} entryType={'manual'}/>
			<ProductRow
				header
				item={{
					id: 0,
					item_code: t('Item Code'),
					date: t('Last Updated'),
					barcode: t('Barcode'),
					name_en: t('Name'),
					price: t('Price'),
					is_return: t('Return'),
					stock: t('Stock out'),
					expiry_date: t('Expiry Date'),
				}}
			/>
			<ProductTable entryType={'manual'} />
			<Footer />
		</div>
	)
}

export default ManualStockOutContainer
