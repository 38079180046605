import axios from "axios";
import { data } from "./../utils/offline/customers.json";



const CutomerAPIs = {

    getCustomer: async (phone) => {
        // console.log(phone);
        try {
            if (navigator.onLine) {
                let ok = false;
                let data2;
                const cancelTokenSource = axios.CancelToken.source();
                data2 = axios.get(process.env.REACT_APP_URL_API + '/get_customer?phone=' + phone, {
                    cancelToken: cancelTokenSource.token
                })
                    .then(res => {
                        ok = true;
                        console.log(res.data, "RESPONSE DATA");
                        return res.data;
                    })
                    .catch(async function (thrown) {
                        if (axios.isCancel(thrown)) {
                            return [];

                        } else {
                            console.warn(thrown);
                        }
                    });
                setTimeout(async () => {
                    if (!ok) {
                        cancelTokenSource.cancel('Request Failed Due to Slow Internet , Getting Data from Offline Mode (Customer Data)');
                    } else {
                        return data2;
                    }
                }, 1000);
                return data2;

            } else {
               return [];
            }
        } catch (error) {
            if (!error.response) {
                return [];
            } else {
                throw error;
            }
        }
    },

    getCustomerById: async (id) => {
        try {
            if (navigator.onLine) {
                let ok = false;
                let data2;
                const cancelTokenSource = axios.CancelToken.source();
                data2 = axios.get(process.env.REACT_APP_URL_API + '/get_customer?id=' + id, {
                    cancelToken: cancelTokenSource.token
                })
                    .then(res => {
                        ok = true;
                        console.log(res.data, "RESPONSE DATA");
                        return res.data;
                    })
                    .catch(async function (thrown) {
                        if (axios.isCancel(thrown)) {
                            console.log( "OFFLINE AXIOS LOCAL DATA");
                            //
                            // const customers = [...data];
                            // const response = await customers.filter(e => e.id_number.endsWith(id));
                            return [];
                        } else {
                            console.warn(thrown);
                        }
                    });
                setTimeout(async () => {
                    if (!ok) {
                        cancelTokenSource.cancel('Request Failed Due to Slow Internet , Getting Data from Offline Mode (Customer Data)');
                    } else {
                        return data2;
                    }
                }, 1000);
                return data2;
            } else {
                console.log( "OFFLINE LOCAL DATA");
                // const customers = [...data];
                // const response = await customers.filter(e => e.id_number.endsWith(id));
                return [];
            }
        } catch (error) {
            if (!error.response) {
                console.log( "ERROR THAN OFFLINE LOCAL DATA");
                // const customers = [...data];
                // const response = await customers.filter(e => e.id_number.endsWith(id));
                return [];
            } else {
                throw error;
            }
        }
    },

    addCustomer: async (customer) => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_API + `/add_customer?phone=${customer?.phone}&name=${customer?.name}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },


}

export default CutomerAPIs;
