import React, { useEffect, useState } from "react";
import "./App.css";
import AppRouter from "./routes";
import store from "./state/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import crossBrowserListener from "./utils/reduxpersist-listener";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import emptyCache from "./utils/common";

export default function App() {

  const [isLoaded, setIsLoaded] = useState(false)
  const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
  };

  const persistor = persistStore(store);
  store.persistor = persistor;

  window.addEventListener(
    "storage",
    crossBrowserListener(store, persistConfig)
  );
  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    setTimeout(() => {
    setIsLoaded(true)
   }, 2000);
  }, [])
  if(!isLoaded){
    return (
      <div className="bg-1" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh",flexDirection:"column"}} ><h2 style={{color:"#fff"}}>Welcome to Kim Mart POS</h2><div className="lds-ripple"><div></div><div></div></div>
      <div style={{color:"white",position:"absolute",bottom:10,left:"45%"}}>Version 1.0.3</div>
      </div>
    )
  }

  return (
   
    <PersistGate  loading={<div className="bg-1" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh",flexDirection:"column"}} ><h2 style={{color:"#fff"}}>Welcome to Kim Mart POS</h2><div className="lds-ripple"><div></div><div></div></div><div style={{color:"white",position:"absolute",bottom:10,left:"45%"}}>Version 1.0.3</div></div>} persistor={persistor}>
      <AppRouter />
    </PersistGate>
  );
}
