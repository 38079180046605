import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as commonActions from "../../../state/common/commonActions";
import BarcodeReader from "react-barcode-reader";
import ProductService from "../../../api/ProductAPI";
import OrderService from "../../../api/OrderAPI";
import InvoiceService from "../../../api/InvoiceAPI";
import { getTens, n_format, n_round, togglePOSFullScreen } from "../../../utils/common";
import { useHistory } from "react-router";

function ConfirmModal({
  show,
  toggle,
  toggleInvoice,
  returnId,
  getInvoices,
  completeData,
}) {
  console.log(completeData);
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);
  const suspend = useSelector((state) => state.suspend);

  const KHR_RATE = common?.currencies[0];
  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);
  const [isSaving, setIsSaving] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setOrderAmount();
  }, [order.products, show]);

  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e.quantity) * n_format(e.price);
      const discount =
        (n_format(oldTotal) * n_format(e.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = +pureQuantity + +e.quantity;
      purePoints =
        +purePoints + (+e.quantity * e.price * (e?.point || 0)) / 100;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount) - n_format(discount));
  };

  const onOk = () => {
    // On CLosed
    togglePOSFullScreen(true);
    toggleInvoice();
    setTimeout(() => {
      window.print();
    }, 100);
  };


  const back = async () => {
    togglePOSFullScreen(true);
    if(!isSaving){
    // TODO: Reset and then back
    // const uniq_id = await InvoiceService.getInvoiceCode({branch:user?.data?.branch?.id});
    setIsSaving(true)
    const data = {
      id: completeData?.order?.invoice?.id,
      customer: completeData?.customer,
      amountToReturn: completeData?.amountToReturn,
      discountReturn: completeData?.order?.invoice?.total_discount,
      grandTotalReturn: completeData?.grandTotalReturn,
      returnPoints: completeData?.returnPoints,
      products: completeData?.order?.products,
      redeemAmount: completeData?.redeemAmount,
    };
    console.log(completeData);
    console.log(data);
    await InvoiceService.manualReturnInvoice(data);
    setIsSaving(false)
    toggle();
    history?.push("/home");
    //  getInvoices();
  }
  };

  const renderModal = () => {
    return (
      <div className="column" style={{ paddingBottom: 40 }}>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Total:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ${n_format(completeData?.amountToReturn)}
          </div>
        </div>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Discount:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ${n_format(completeData?.discountReturn)}
          </div>
        </div>
        {completeData?.customer?.data?.phone ? (
          <div className="row justify-between" style={{ marginTop: 10 }}>
            <div className="text-1" style={{ fontSize: 15 }}>
              Return Reward Points:
            </div>
            <div className="text-1" style={{ fontSize: 15 }}>
              {n_format(completeData?.returnPoints)}
            </div>
          </div>
        ) : null}
        {completeData?.customer?.data?.phone ? (
          <div className="row justify-between" style={{ marginTop: 10 }}>
            <div className="text-1" style={{ fontSize: 15 }}>
              Added Redeem Points:
            </div>
            <div className="text-1" style={{ fontSize: 15 }}>
              {completeData?.order?.invoice?.redeem_amount}
            </div>
          </div>
        ) : null}
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Net Total:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ${n_format(completeData?.grandTotalReturn)}
          </div>
        </div>

        {/* <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 13 }}>
            Riel Below $100 : $10 X $ {n_format(getTens((completeData?.grandTotalReturn )))}
          </div>
          <div className="text-1" style={{ fontSize: 13 }}>
          ៛{" "}{(getTens
                      ((completeData?.grandTotalReturn )) * USD_RATE?.value
                    )}
          </div>
        </div> */}
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Riel Below $10 : $ {n_format(completeData?.grandTotalReturn % 10)}
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ៛ {n_round(n_format(completeData?.grandTotalReturn % 10) * parseFloat(completeData?.order?.invoice?.currency_rate ?? USD_RATE?.value))}
          </div>
        </div>
        {/* EMERGENT TODO REDEEM MINUS FROM ORDER ADD TO CUSTOMER */}
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Riel Below $1 : $ {n_format(completeData?.grandTotalReturn % 1)}
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            {USD_RATE?.symbol}{" "}
            {n_round(n_format(completeData?.grandTotalReturn % 1) * parseFloat(completeData?.order?.invoice?.currency_rate ?? USD_RATE?.value))}
          </div>
        </div>
        <div
          className="column align-center justify-center"
          style={{ marginTop: 50 }}
        >
          <div className="text-1" style={{ fontSize: 15 }}>
            Amount To Return:
          </div>
          <input
            type="text"
            className="w-100"
            value={"$ " + n_format(+completeData?.grandTotalReturn)}
            style={{
              marginBottom: 10,
              padding: 6,
              fontSize: 25,
              fontWeight: "bold",
              fontFamily: "Arail",
              textAlign: "right",
            }}
          />
        </div>
        <div className="w-100  justify-between row">
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">CODE</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">Description</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">Amount</span>
            </div>
          </div>
        </div>
        <div className="w-100  justify-between row">
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">KHR</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">Khmer Riel</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">
                {n_round(n_format(+completeData?.grandTotalReturn) * parseFloat(completeData?.order?.invoice?.currency_rate ?? USD_RATE?.value))}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // EMERGENCY TODO: REDEEM AMOUNT WHEN MULTIPLE INVOICE RETURN
  return (
    <CommonModal
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      title_1="Print"
      header="Change Information"
      title_2="Close"
      isSaving={isSaving}
      cancel
      back={false}
      handleBack={() => {
        setIsSaving(false)
        toggle();
      }}
      toggleModal={back}
    />
  );
}

export default ConfirmModal;
