import axios from "axios";
import { data } from "./../utils/offline/products.json";

const productsAPI = {
  getAllProducts: async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API + "/products");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFavProducts: async (branch_id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_API + "/search/products?favourites=1&branch=" + branch_id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  searchProducts: async (query) => {
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API

    try {
      if (navigator.onLine) {








        // console.log(process.env.REACT_APP_URL_API + "/search/products?barcode=" + query);
        // const response = await axios.get(
        //   process.env.REACT_APP_URL_API + "/search/products?barcode=" + query
        // );
        // console.log(response.data);
        // return response.data;





        let ok = false;
        let data2;
        const cancelTokenSource = axios.CancelToken.source();
        data2 = axios.get(process.env.REACT_APP_URL_API + "/search/products?" + new URLSearchParams(query).toString(), {
          cancelToken: cancelTokenSource.token
        })
          .then(res => {
            ok = true;
            console.log(res.data, "RESPONSE DATA");
            return res.data;
          })
          .catch(async function (thrown) {
            if (axios.isCancel(thrown)) {
              console.log('Request canceled', thrown.message);
              const products = [...data];
              if (query) {
                const response = await products?.filter(e => e?.barcode === query);
                console.log(response, "JSONDATA2");
                return { data: response };
              }
              return { data: [] }
            } else {
              console.warn(thrown);
            }
          });
        setTimeout(async () => {
          if (!ok) {
            cancelTokenSource.cancel('Request Failed Due to Slow Internet , Getting Data from Offline Mode (Barcode Data)');
          } else {
            return data2;
          }
        }, 10000);
        return data2;























      } else {
        const products = [...data];
        if (query) {
          const response = await products?.filter(e => e?.barcode === query);
          console.log(response, "JSONDATA2");
          return { data: response };
        }
        return { data: [] }
      }
    } catch (error) {
      if (!error.response) {
        const products = [...data];
        if (query) {
          const response = await products?.filter(e => e.barcode === query);
          console.log(response, "JSONDATA2");
          return { data: response };
        }
        return { data: [] }
      } else {
        throw error;
      }
    }
  },

  getBagProduct: async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API + "/bag_product");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  searchProductsWith: async (query, price, limit, page) => {

    let ok = false;
    let data2;
    try {
      if (navigator.onLine) {
        const cancelTokenSource = axios.CancelToken.source();
        data2 = axios.get(`${process.env.REACT_APP_URL_API}/search_products?query=${query ?? ""}&limit=${limit}&page=${page}&price=${price ?? ""}`, {
          cancelToken: cancelTokenSource.token
        })
          .then(res => {
            ok = true;
            console.log(res.data, "RESPONSE DATA");
            return res.data;
          })
          .catch(async function (thrown) {
            if (axios.isCancel(thrown)) {
              console.log('Request canceled', thrown.message);
              const products = [...data];
              if (price && !query) {

              } else if (!price && query) {

              }
              const response = await products?.slice(0, limit ?? 50);
              console.log({ data: response }, "JSONDATA");
              data2 = { data: response };
              return { data: response };
            } else {
              console.warn(thrown);
            }
          });
        setTimeout(async () => {
          if (!ok) {
            cancelTokenSource.cancel('Request Failed Due to Slow Internet , Getting Data from Offline Mode');
          } else {
            return data2;
          }
        }, 3000);
        return data2;
        // cancel the request by checking the promise

      }
      else {
        const products = [...data];
        if (price && !query) {

        } else if (!price && query) {

        }
        const response = await products?.slice(0, limit ?? 50);
        console.log(response, "JSONDATA");
        return { data: response };
      }

    } catch (error) {
      if (!error.response) {
        const products = [...data];
        if (price && !query) {

        } else if (!price && query) {

        }
        const response = await products?.slice(0, limit ?? 50);
        console.log(response, "JSONDATA");
        return { data: response };
      }
      else {
        throw error;
      }
    }
  },
};

export default productsAPI;
